var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company-selector",on:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}}},[_c('div',{staticStyle:{"width":"100%"},on:{"click":function($event){_vm.showPanel = true}}},[_c('CSSelect',{staticStyle:{"width":"100%"},attrs:{"height":"40px","iWidth":"36px"}},[_c('div',{style:({
                    padding: '10px',
                    overflow: 'hidden',
                    'text-overflow': 'ellipsis',
                    'white-space': 'nowrap',
                    color:
                        _vm.selectedTenant.companyName || _vm.lesseeCompanyName ? '#000' : '#999',
                })},[_vm._v(" "+_vm._s(_vm.selectedTenant.companyName || _vm.lesseeCompanyName || "请选择")+" ")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPanel),expression:"showPanel"}],staticClass:"company-panel"},[_c('input',{staticClass:"cs-input",staticStyle:{"width":"100%","padding-left":"10px","border-radius":"4px","outline":"none","border":"1px solid #979797"},attrs:{"placeholder":"搜索"},on:{"keyup":_vm.keywordListener}}),_c('ul',_vm._l((_vm.tenantList),function(tenant){return _c('li',{key:tenant.id,on:{"click":function($event){return _vm.changeSelectedTenant(tenant)}}},[_c('p',{staticStyle:{"color":"#000","margin-bottom":"0"}},[_vm._v(" "+_vm._s(tenant.companyName || tenant.deputyName)+" ")]),_c('p',{staticStyle:{"color":"#000","margin-bottom":"0"}},[_vm._v(" "+_vm._s(tenant.deputyName)+"("+_vm._s(tenant.deputyPhone)+") ")])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="company-selector" @click.stop="() => {}">
        <div @click="showPanel = true" style="width: 100%">
            <CSSelect height="40px" style="width: 100%" iWidth="36px">
                <div
                    :style="{
                        padding: '10px',
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                        'white-space': 'nowrap',
                        color:
                            selectedTenant.companyName || lesseeCompanyName ? '#000' : '#999',
                    }"
                >
                    {{ selectedTenant.companyName || lesseeCompanyName || "请选择" }}
                </div>
            </CSSelect>
        </div>
        <div class="company-panel" v-show="showPanel">
            <input
                class="cs-input"
                style="
                    width: 100%;
                    padding-left: 10px;
                    border-radius: 4px;
                    outline: none;
                    border: 1px solid #979797;
                "
                placeholder="搜索"
                @keyup="keywordListener"
            />
            <ul>
                <li
                    v-for="tenant in tenantList"
                    :key="tenant.id"
                    @click="changeSelectedTenant(tenant)"
                >
                    <p style="color: #000; margin-bottom: 0">
                        {{ tenant.companyName || tenant.deputyName }}
                    </p>
                    <p style="color: #000; margin-bottom: 0">
                        {{ tenant.deputyName }}({{ tenant.deputyPhone }})
                    </p>
                    <!-- <p v-if="isMeetingRoomUse">
            {{ tenant.deputyName }}({{ tenant.deputyPhone }})
          </p> -->
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { queryTenantUrl } from "@/requestUrl";
import CSSelect from "@/components/common/CSSelect";
import _ from "lodash";

export default {
    props: {
        defaultTenantId: [Number, String],
        // 会议室使用
        isMeetingRoomUse: {
            type: Boolean,
            default: false,
        },
        lesseeCompanyName: String,
        addParameter:{
            type: Boolean,
            default: false
        }
    },
    components: {
        CSSelect,
    },
    name: "CompanySelect",
    data() {
        return {
            showPanel: false,
            tenantList: [],
            selectedTenant: {},
        };
    },
    created(){
        console.log("Company")
    },
    watch: {
        async defaultTenantId(val) {
            console.log(val, "租客信息");
            const tenantList =
                this.tenantList.length > 0
                    ? this.tenantList
                    : await this.getTenantList();
            tenantList.forEach((tenant) => {
                console.log("租客信息对比结果", val == tenant.id);
                if (val == tenant.id) {
                    this.changeSelectedTenant(tenant);
                }
            });
        },
    },
    methods: {
        /**
         * 更改选中的租客
         * @param {Object} tenantInfo 租客信息
         */
        changeSelectedTenant(tenantInfo) {
            this.selectedTenant = tenantInfo;
            this.showPanel = false;
            this.$emit("change-lesse-info", tenantInfo);
        },
        /**
         * 监听关键字输入
         * @param {KeyboardEvent} e
         *  */
        keywordListener(e) {
            _.debounce(() => {
                this.getTenantList(e.target.value);
            }, 1000)();
        },
        //  获取租客列表
        async getTenantList(keyword) {
            let params = {
                regionId: this.$vc.getCurrentRegion().communityId,
                keyword: keyword || ""
            }
            if(this.addParameter){params = {
                regionId: this.$vc.getCurrentRegion().communityId,
                keyword: keyword || "",
                relevance :true
            }
            }

            console.log(params);
            return this.$fly
                .post(queryTenantUrl,params)
                .then((res) => {
                    this.tenantList = res.data.datas;
                    return res.data.datas;
                });
        },
    },
    mounted() {
        document.querySelector("body").addEventListener("click", () => {
            this.showPanel = false;
        });
    },
    created() {
        this.getTenantList();
    },
};
</script>

<style>
.company-selector .custom-input {
    border: 1px solid #444;
}

.company-selector {
    position: relative;
}

.company-panel {
    width: 380px;
    /* min-height: 290px; */
    background: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 2;
    padding: 10px;
    /* border: 1px dashed #999999; */
}

.company-panel ul {
    height: 190px;
    margin-top: 15px;
    overflow-y: auto;
}

.company-panel li {
    font-size: 24px;
    padding: 15px 0;
}

.company-panel li:not(:last-of-type) {
    border-bottom: 1px solid #e6e6e6;
}

.company-panel li div:not(:last-of-type) {
    margin-bottom: 10px;
}
.company-panel .custom-input {
    width: 350px;
    height: 40px;
    margin: 10px 10px;
}
.el-upload__tip {
    font-size: 20px;
    padding-top: 4px;
    color: gray;
}

.company-panel p {
    padding: 0 15px;
}
.company-selector .custom-input {
    width: 220px;
}
.icon_ {
    position: absolute;
    top: 7px;
    left: 193px;
}
.company-selector .tenant_ {
    width: 250px;
    padding: 0px 15px 0 10px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* max-width: 219px; */
    color: #999;
}
</style>

<template>
    <div class="step-component row">
        <div class="col-sm-11">
            <div class="row mb-2">
                <div class="col-sm-2 text-right leftFloat">租客</div>
                <div class="col-sm-8 leftFloat">
                    <CompanySelect
                        style="width: 240px"
                        class="leftFloat"
                        :default-tenant-id="selectedLessId"
                        @change-lesse-info="changeLesseInfo"
                        :addParameter="true"
                    ></CompanySelect>
                    <!-- <button class="btn btn-primary leftFloat">新建</button> -->
                </div>
            </div>
            <div class="row mb-2">
                <div style="padding-top: 6px" class="col-sm-2 text-right">
                    附件
                </div>
                <div class="col-sm-8">
                    <div class="row" style="margin-left: 0;">
                        <p
                            v-for="(attachment, aIndex) in attachments"
                            style="display: block; width: 100%;"
                            :key="aIndex + 'attachment'"
                        >
                            <span
                                style="
                                    display: inline-block;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    max-width: 50%;
                                    vertical-align: middle;
                                "
                            >
                                {{
                                    attachment.content.split("/")[
                                        attachment.content.split("/").length - 1
                                    ]
                                }}
                            </span>
                            <button
                                v-if="['png', 'jpg', 'jpeg', 'gif', 'webp',].includes(
                                        attachment.content.split('.')[attachment.content.split('.').length - 1]
                                )"
                                class="btn btn-link"
                                @click="lookAttachment(attachment.content)"
                            >
                                查看
                            </button>

                            <button
                                class="btn btn-link"
                                @click="removeAttachment(aIndex)"
                            >
                                删除
                            </button>
                        </p>
                    </div>
                    <div class="row" style="margin-left: 0;">
                        <span class="btn btn-link" @click="arouseChoosePhoto"
                            >上传文件</span
                        >
                        <input
                            type="file"
                            class="file"
                            accept="images/*"
                            id="uploadStaffPhoto"
                            hidden
                            @change="chooseStaffPhoto($event)"
                        />
                        <div slot="tip" class="el-upload__tip">
                            <svg
                                class="icon"
                                aria-hidden="true"
                            >
                                <use xlink:href="#icon-menua-zu92"></use>
                            </svg>
                            可上传电子合同、合同照片。
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-2 text-right">备注</div>
                <div class="col-sm-8">
                    <textarea
                        v-model="comment"
                        style="
                            width: 720px;
                            height: 200px;
                            border: none;
                            outline: none;
                            padding: 10px;
                            white-space: normal;
                            word-break: break-all;
                            word-wrap: break-word;
                            border-radius: 6px;
                            background: #efeff0;
                            resize: none;
                        "
                        placeholder="限50个字"
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CompanySelect from "@/components/CompanySelect.vue";

export default {
    props: {
        selectedLessId: [String, Number],
    },
    name: "ContractFourthStep",
    components: {
        CompanySelect,
    },
    data() {
        return {
            comment: "",
            lesseeId: "",
            attachments: [],
            lesseeCompany: "",
        };
    },
    methods: {
        /**
         * 删除附件
         * @param {Number} index 要被删除的附件的下标
         *
         *  */
        removeAttachment(index) {
            this.attachments.splice(index, 1);
        },
        /**
         * 查看附件
         * @param {String} src 附件地址
         *  */
        lookAttachment(src) {
            this.$vc.emit(this.$route.path, "viewModal", "openViewModal", { photos: src });
        },
        //  调起选择文件
        arouseChoosePhoto() {
            $("#uploadStaffPhoto").trigger("click");
        },
        // 上传图片
        async uploadPhoto(base64) {
            return new Promise((reslove, reject) => {
                this.$vc.http.post(
                    "uploadImg",
                    "upload",
                    {
                        img: base64,
                    },
                    {
                        headres: {
                            "Content-Type": "application/json",
                        },
                    },
                    (resStr, res) => {
                        reslove(res.body.fileSaveName);
                    },
                    (errText, err) => {
                        reject(errText);
                    }
                );
            });
        },
        // 选择文件后处理
        chooseStaffPhoto(event) {
            const files = event.target.files;
            if (files && files.length > 0) {
                // 获取目前上传的文件
                const file = files[0]; // 文件大小校验的动作
                this.$fly.putFile(file)
                    .then(res => {
                        this.attachments.push({ content: res.url });
                    })

            }
        },

        /**
         * 更改租客
         * @param {Object} lesseeInfo 租客信息
         *  */
        changeLesseInfo(lesseeInfo) {
            this.lesseeCompany = lesseeInfo.companyName;
            this.lesseeId = lesseeInfo.id;
        },
        getData() {
            const { comment, lesseeId, attachments, lesseeCompany } = this;
            return { comment, lesseeId, attachments, lesseeCompany };
        },
    },
};
</script>

<style lang="stylus">
.attachments-img
    width 120px
    height 120px
    border 1px solid #e6e6e6
    border-radius 5px
    margin-bottom 10px
    margin-left 20px
    /* &:not(:nth-of-type(5n)) */
.text-right .custom-input
    width 200px
    height 100px
    outline none
</style>
